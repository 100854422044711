




































































































































































































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import { ApiResource } from '@/types';

interface VuexBindings {
  detect: (idOrVanity: string) => ApiResource.Business,
}

export default (Vue as VueConstructor<Vue & VuexBindings>).extend({
  props: {
    businessVanity: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      detect: 'business/Detect',
    }),
    BUSINESS(): ApiResource.Business {
      return this.detect(this.businessVanity);
    },
    LIMITS(): ApiResource.Business.LimitsData {
      return this.BUSINESS.limits;
    },
    SLOTS(): ApiResource.Business.SlotsData {
      return this.BUSINESS.slots;
    },
    WAITLIST(): ApiResource.Business.WaitlistData {
      return this.BUSINESS.waitlist;
    },
  },
});
