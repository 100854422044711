var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('h3',{staticClass:"text-2xl font-semibold mb-3",domProps:{"textContent":_vm._s(_vm.$t('title'))}}),_c('div',{staticClass:"table-container"},[_c('BaseTable',{staticClass:"is-hoverable is-fullwidth text-lg"},[_c('thead',[_c('tr',[_c('th',{domProps:{"textContent":_vm._s(_vm.$t('limit.head.metric'))}}),_c('th',{attrs:{"align":"right"},domProps:{"textContent":_vm._s(_vm.$t('limit.head.value'))}}),_c('th',{domProps:{"textContent":_vm._s(_vm.$t('limit.head.request'))}})])]),_c('tbody',{staticClass:"tabular-nums"},[_c('tr',[_c('td',{domProps:{"textContent":_vm._s(_vm.$t('limit.commissions.open_limit'))}}),_c('td',{staticClass:"text-right",attrs:{"align":""},domProps:{"textContent":_vm._s(_vm.SLOTS.max)}}),_c('td',[_c('RouterLink',{attrs:{"to":{
                name: 'profile.request',
                params: { VANITY: 'support' },
                query: { summary: _vm.$t('request.support', {
                  vanity: _vm.businessVanity,
                  metric: _vm.$t('limit.commissions.open_limit'),
                })},
              }},domProps:{"textContent":_vm._s(_vm.$t('limit.request-increase'))}})],1)]),_c('tr',[_c('td',{domProps:{"textContent":_vm._s(_vm.$t('limit.waitlist.max_length'))}}),_c('td',{staticClass:"text-right",attrs:{"align":""},domProps:{"textContent":_vm._s(_vm.WAITLIST.max)}}),_c('td',[(!_vm.BUSINESS.pricing.is_plus)?_c('RouterLink',{attrs:{"to":{ name: 'dashboard.pricing', query: { plus: '1' } }},domProps:{"textContent":_vm._s(_vm.$t('limit.go-plus'))}}):_c('RouterLink',{attrs:{"to":{
                name: 'profile.request',
                params: { VANITY: 'support' },
                query: { summary: _vm.$t('request.support', {
                  vanity: _vm.businessVanity,
                  metric: _vm.$t('limit.waitlist.max_length'),
                })},
              }},domProps:{"textContent":_vm._s(_vm.$t('limit.request-increase'))}})],1)]),_c('tr',[_c('td',{domProps:{"textContent":_vm._s(_vm.$t('limit.commissions.user_limit'))}}),_c('td',{staticClass:"text-right",attrs:{"align":""},domProps:{"textContent":_vm._s(_vm.SLOTS.open_per_user === 1
              ? _vm.$t('common.no')
              : ((_vm.$t('common.yes')) + ", " + (_vm.SLOTS.open_per_user)))}}),_c('td',[_c('RouterLink',{attrs:{"to":{ name: 'dashboard.requests', hash: '#enable-multiple-requests-per-user' }},domProps:{"textContent":_vm._s(_vm.$t('limit.change'))}})],1)]),_c('tr',[_c('td',{domProps:{"textContent":_vm._s(_vm.$t('limit.commission_items.item_min'))}}),_c('td',{staticClass:"text-right",attrs:{"align":""}},_vm._l((_vm.LIMITS.items.amount_min),function(amount,currency){return _c('p',{key:currency,domProps:{"textContent":_vm._s(_vm.$n(amount / 100, currency))}})}),0),_c('td',[_c('RouterLink',{attrs:{"to":{
                name: 'profile.request',
                params: { VANITY: 'support' },
                query: { summary: _vm.$t('request.support', {
                  vanity: _vm.businessVanity,
                  metric: _vm.$t('limit.commission_items.item_min'),
                })},
              }},domProps:{"textContent":_vm._s(_vm.$t('limit.request-decrease'))}})],1)]),_c('tr',[_c('td',{domProps:{"textContent":_vm._s(_vm.$t('limit.commission_items.item_max'))}}),_c('td',{staticClass:"text-right",attrs:{"align":""}},_vm._l((_vm.LIMITS.items.amount_max),function(amount,currency){return _c('p',{key:currency,domProps:{"textContent":_vm._s(_vm.$n(amount / 100, currency))}})}),0),_c('td',[_c('RouterLink',{attrs:{"to":{
                name: 'profile.request',
                params: { VANITY: 'support' },
                query: { summary: _vm.$t('request.support', {
                  vanity: _vm.businessVanity,
                  metric: _vm.$t('limit.commission_items.item_max'),
                })},
              }},domProps:{"textContent":_vm._s(_vm.$t('limit.request-increase'))}})],1)]),_c('tr',[_c('td',{domProps:{"textContent":_vm._s(_vm.$t('limit.commission_items.total_max'))}}),_c('td',{staticClass:"text-right",attrs:{"align":""}},_vm._l((_vm.LIMITS.items.total_max),function(amount,currency){return _c('p',{key:currency,domProps:{"textContent":_vm._s(_vm.$n(amount / 100, currency))}})}),0),_c('td',[_c('RouterLink',{attrs:{"to":{
                name: 'profile.request',
                params: { VANITY: 'support' },
                query: { summary: _vm.$t('request.support', {
                  vanity: _vm.businessVanity,
                  metric: _vm.$t('limit.commission_items.total_max'),
                })},
              }},domProps:{"textContent":_vm._s(_vm.$t('limit.request-increase'))}})],1)]),_c('tr',[_c('td',{domProps:{"textContent":_vm._s(_vm.$t('limit.profile.pages_limit'))}}),_c('td',{staticClass:"text-right",attrs:{"align":""},domProps:{"textContent":_vm._s(_vm.LIMITS.pages)}}),_c('td',[_c('RouterLink',{attrs:{"to":{
                name: 'profile.request',
                params: { VANITY: 'support' },
                query: { summary: _vm.$t('request.support', {
                  vanity: _vm.businessVanity,
                  metric: _vm.$t('limit.profile.pages_limit'),
                })},
              }},domProps:{"textContent":_vm._s(_vm.$t('limit.request-increase'))}})],1)])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }